<template>
  <base-page>
    <titlu-pagina
      Titlu="Methods Of Preparation"
      @on_add_clicked="show_dialog()"
      :AdaugaVisible="true" />

    <el-table :data="Results">
      <el-table-column prop="Name" label="Name"></el-table-column
      ><el-table-column fixed="right" label="Actions" width="200px">
        <template slot-scope="scope">
          <el-tooltip content="Edit">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="show_dialog(scope.row.Id)"
            />
          </el-tooltip>

          <el-tooltip content="Delete">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="delete_item(scope.row)"
            />
          </el-tooltip> </template></el-table-column></el-table
    ><el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager" />
    <Methods-of-preparation-dialog ref="dlg" @save="refresh_info()"
  /></base-page>
</template>


<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Methods_of_preparation_dialog from "@/pages/methods_of_preparation/Methods_of_preparation_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
  name: "methods_of_preparation",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Methods-of-preparation-dialog": Methods_of_preparation_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      Results: [],
      base_url: "",
      Info: {},
      Filters: {},
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      var response = await this.post("methods_of_preparation/get_info");
      this.refresh_info();
    },

    async refresh_info() {
      var response = await this.post("methods_of_preparation/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("methods_of_preparation");
    },
    reset() {
      this.Filters = {};
      this.refresh_info();
    },

    async delete_item(item) {
      var confirm = await this.$confirm(`Sunteti sigur ?`, "Warning");
      if (confirm) {
        await this.post("methods_of_preparation/delete_item", { id: item.Id });
        this.refresh_info();
      }
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

.filtre {
  .el-input-number {
    width: 100% !important;
  }
}
</style>
